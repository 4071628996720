import React from 'react';

const lang = {
	login: {
		welcome: 'Ласкаво просимо!',
		email: 'Електронна пошта',
		password: 'Пароль',
		submit: 'Увійти',
		forgotten_password: 'Забули пароль',
		error_title: 'Помилка входу',
		error_content: 'Спробуйте ввести дані ще раз. Якщо це не допоможе, натисніть "Забули пароль".',
	},
	logout: 'Вийти',
	forgotten_password: {
		back_to_login: 'Назад до входу',
		email: 'Електронна пошта',
		code: 'Код з електронної пошти',
		password: 'Новий пароль',
		password_confirmation: 'Підтвердження пароля',
		submit_email: 'Надіслати електронний лист',
		submit_code: 'Перевірити код',
		submit_password: 'Змінити пароль',
		success_title: 'Електронний лист надіслано',
		success_content: 'Електронний лист для відновлення пароля було надіслано на адресу ',
		error_title: 'Виникла помилка',
		error_content: 'При відновленні пароля виникла помилка, спробуйте ще раз.',
	},
	navigation: {
		back: 'Назад',
	},
	modals: {
		ok: 'ОК',
		cancel: 'Скасувати',
	},
	menu: {
		home: 'Додому',
		orders: 'Прибирання',
		shifts: 'Розклад',
		settings: 'Налаштування',
		salary: 'Заробіток',
		training: 'Навчання',
		standards: 'Стандарти прибирання',
		legal: 'Безпекові листи та інструкції',
		contact: 'Контакти',
		warehouse: 'Склад Брно',
		phone: 'Телефон',
		links: 'Важливі посилання',
		prices: 'Прайс лист локацій',
		toc: 'Загальні положення та умови',
	},
	settings: {
		name: 'Ім\'я',
		email: 'Електронна пошта',
		phone: 'Телефон',
		address: 'Адреса',
		bank_account: 'Банківський рахунок',
		birthday: 'Дата народження',
		drivers_licence: 'Водійське посвідчення',
		zones: 'Зони',
		password: 'Пароль',
		change_password: 'Змінити',
		personal_info: 'Особиста інформація',
		alert_change_details: 'Якщо вам потрібно змінити ваші дані, оскільки вони вже не актуальні, напишіть нам на info@youklid.cz',
	},
	days: [
		{
			short: 'Пн',
			long: 'Понеділок',
		},
		{
			short: 'Вт',
			long: 'Вівторок',
		},
		{
			short: 'Ср',
			long: 'Середа',
		},
		{
			short: 'Чт',
			long: 'Четвер',
		},
		{
			short: 'Пт',
			long: 'П\'ятниця',
		},
		{
			short: 'Сб',
			long: 'Субота',
		},
		{
			short: 'Нд',
			long: 'Неділя',
		},
	],
	months: [
		'Січень',
		'Лютий',
		'Березень',
		'Квітень',
		'Травень',
		'Червень',
		'Липень',
		'Серпень',
		'Вересень',
		'Жовтень',
		'Листопад',
		'Грудень',
	],
	orders: {
		confirm_headline: 'Прибирання до підтвердження',
		detail: 'Деталі',
		confirm: 'Прийняти',
		fill_report: 'Заповнити звіт',
		begin_timer: 'Розпочати прибирання',
		end_timer: 'Завершити прибирання',
		active: 'Активне прибирання',
		next: 'Наступне прибирання',
		map: 'Карта',
		no_orders: 'На даний момент у вас немає призначених прибирань',
		orders: 'Прибирання',
		hide: 'Сховати',
		more: 'Більше прибирань...',
		method: 'Метод',
		bonus: 'Бонус',

		important: 'Важливо',
		details: 'Деталі',
		actions: 'Дії',
		first_cleaning_headline: 'Це перше прибирання клієнта з вами',
		first_cleaning_checklist:
			<>
				<li><strong>Представтесь</strong> і скажіть, що ви прийшли виконати <strong>Youklid</strong></li>
				<li>Попросіть його показати вам квартиру та <strong>повідомити</strong> вам <strong>свої вимоги до прибирання</strong></li>
				<li>Попросіть його показати вам <strong>де знаходяться засоби, швабра, пилосос і сміттєві пакети 🧹</strong></li>
				<li>Дізнайтеся, чи є у клієнта квіти і як їх поливати (краще менше, ніж більше!).</li>
				<li>Якщо вам потрібно використовувати <strong>праску, пральну машину або інші прилади - не соромтеся запитати, як ними користуватися</strong></li>
				<li>Скажіть клієнту коротко, <strong>що ви будете робити і з чого почнете</strong></li>
				<li>Запитайте, чи є у нього ще якісь <strong>побажання щодо прибирання</strong></li>
				<li><strong>Подякуйте</strong> і беріться до роботи 😊</li>
			</>,
		cards_cards: 'Залиште у клієнта картку з номером',
		cards_description: 'Якщо у вас немає картки, нічого не потрібно робити. Ми регулярно роздаємо картки, і вони служать нашим клієнтам як подяка.',
		date: 'Дата',
		duration: 'Тривалість',
		note: 'Примітка',
		note_1: <>Примітка<br />(від клієнта)</>,
		note_2: <>Примітка<br />(від youklid)</>,
		note_3: <>Примітка<br />(Будинок)</>,
		note_4: <>Примітка<br />(про клієнта)</>,
		address: 'Адреса',
		route: 'Маршрут',
		travel_time: 'Час, який займає дорога на прибирання з вашого дому.',
		minutes: 'хв',
		phone: 'Телефон',
		cleaning_order: 'Порядок',
		cleaning: 'прибирання',
		id: 'ID',
		frequency: 'Частота',
		work_time: 'Відпрацьовано',
		unfinished: 'Не завершено',
		client: 'Клієнт',
		services: 'Послуги',
		cleaning_uppercase: 'Прибирання',
		no_services: 'Клієнт не ввів жодні послуги',
		guides: 'Інструкції',
		cleaning_standards: 'Що ми прибираємо',
		have_cleaning: 'У вас є прибирання',
		started: 'Прибирання розпочато',
		start: 'Розпочати прибирання',
		finished: 'Прибирання завершено',
		finish: 'Завершити прибирання',
		reported: 'Звіт заповнено',
		report: 'Заповнити звіт',

		error_finishing_headline: 'Не вдалося завершити прибирання',
		error_finishing_description: 'Будь ласка, зв\'яжіться з нами, щоб ми могли якнайшвидше виправити помилку.',
		shorter_cleaning: 'Коротше прибирання',
		longer_cleaning: 'Триваліше прибирання',
		should_be_from: 'Прибирання мало бути з',
		by_timer_its_from: 'Згідно з вашим вимірюванням, ви прибиралися з',
		till: 'до',
		thats: 'Це на',
		less_minutes: 'хвилин менше, ніж замовив клієнт.',
		more_minutes: 'хвилин більше, ніж замовив клієнт.',
		finish_short: 'Завершити',
		return: 'Повернутися до прибирання',
		client_approval: 'Продовження прибирання повинен схвалити клієнт. Чи згоден клієнт додатково оплатити час?',
		set_time: 'Я хочу встановити час',
		time_now: 'Поточний час',
		ordered_time: 'Замовленний час',
		notes_to_report: 'Будь ласка, пишіть всі додаткові примітки у звіті. Ми їх прочитаємо та врахуємо.',
		order_not_loaded: 'Ця сторінка погано завантажилась. Будь ласка, оновіть цю сторінку. Якщо це не допоможе, зв`яжіться з нами.',

		report_sent: 'Звіт збережено',
		report_error_headline: 'Помилка з надсиланням',
		report_error_description: 'Виникла помилка під час відправлення звіту.',
		enough_time: 'Чи було достатньо часу на прибирання?',
		ok_time: 'Так, час був достатнім',
		not_enough: 'Ні, часу бракувало',
		better_time: 'Який час ви рекомендуєте?',
		more_information: 'Чи є ще щось, про що ми повинні знати?',
		write_here: 'Напишіть тут...',
		send_report: 'Відправити звіт',
	},
	salaries: {
		salaries_headline: 'Зарплати',
		worked_hours: 'Години роботи',
		bonuses: 'Бонуси до зарплати',
		total: 'Заробітна плата (з бонусом)',
		hour_short: 'г',
		kc_short: 'кр',
		disclaimer: <>
			Чисту зарплату, яка приходить на ваш рахунок після відрахування обов&apos;язкового соціального та медичного страхування, можна розрахувати на сторінці <a href="https://www.vypocet.cz/cista-mzda">калькулятора валової зарплати</a> (для ДПП понад 10000 крон щомісяця). Докладнішу інформацію ми зібрали для вас у <a href="https://skoleni.youklid.cz/cista-mzda">статті про розрахунок зарплати</a> у навчанні.
		</>,
		alert_mistake_contact: <>
			Вам не співпадає виплата? Ми радо допоможемо вам з цим, просто напишіть на info@youklid.cz і поясніть, що саме вам не подобається у виплаті. Але спочатку докладно ознайомтеся з <a href="https://skoleni.youklid.cz/cista-mzda">інформацією про законні відрахування</a> у навчанні.
		</>,
	},
	shifts: {
		change_headline: 'Зміна розкладу',
		change_button: 'Налаштування',
		vacation_headline: 'Виключення з розкладу',
		vacation_button: 'Відписатися',
		no_shift: 'Скасований день',
	},
	shift_wizzard: {
		sent_headline: 'Налаштування збережено',
		sent_description: 'Ваші налаштування розкладу були збережені. Тепер ми маємо схвалити вашу зміну, і тоді вам буде показано новий розклад днів.',
		error_headline: 'Щось пішло не так',
		error_description: 'Будь ласка, зв`яжіться з нами, щоб ми могли якнайшвидше усунути помилку.',
		previous: 'Попередній',
		next: 'Наступний',
		send_button: 'Надіслати на обробку',
		page_1_1: 'Ласкаво просимо до налаштування розкладу. Незабаром ви зможете налаштувати, які дні ви хочете прибирати. Пам`ятайте, що у вас є велика свобода налаштувань, але чим менше часу ви оберете, тим менше прибирань до вас дійде.',
		page_1_2: 'Після відправлення вашого бажаного налаштування ми маємо обробити ваш розклад. Ми стежимо за тим, щоб у вас залишилося якомога більше повторюваних прибирань. Зазвичай обробка проходить швидко, у випадку невідповідностей ми зв`яжемося з вами.',
		page_2_1: 'Наша система автоматично заповнює ваш встановлений розклад на кожен майбутній тиждень. Це зроблено переважно для повторюваних прибирань і вашої зручності при введенні. У разі змін ви можете відкоригувати свій розклад за допомогою кнопки для винятків у розкладі.',
		page_2_2: 'Чи хочете ви встановити розклад однаково на кожен тиждень або по-різному для парних і непарних тижнів?',
		weekly: 'Так',
		fortnightly: 'Парні/непарні',
		page_3: 'Тепер виберіть, які дні ви хочете прибирати.',
		even_week: 'Цей тиждень парний',
		odd_week: 'Цей тиждень непарний',
		page_4: 'Встановіть часи, у які ви хочете отримувати замовлення. Чим більше часу, тим більше можливих прибирань.',
		days: [
			[
				'Понеділок',
				'Понеділок (непарний)',
			],
			[
				'Вівторок',
				'Вівторок (непарний)',
			],
			[
				'Середа',
				'Середа (непарний)',
			],
			[
				'Четвер',
				'Четвер (непарний)',
			],
			[
				'П\'ятниця',
				'П\'ятниця (непарний)',
			],
			[
				'Субота',
				'Субота (непарний)',
			],
			[
				'Неділя',
				'Неділя (непарний)',
			],
			[
				'Понеділок',
				'Понеділок (парний)',
			],
			[
				'Вівторок',
				'Вівторок (парний)',
			],
			[
				'Середа',
				'Середа (парний)',
			],
			[
				'Четвер',
				'Четвер (парний)',
			],
			[
				'П\'ятниця',
				'П\'ятниця (парний)',
			],
			[
				'Субота',
				'Субота (парний)',
			],
			[
				'Неділя',
				'Неділя (парний)',
			],
		],
	},
	vacations: {
		page_1: 'Скільки днів?',
		one_day: 'Один день',
		multiple_days: 'Декілька днів',
		sent_headline: 'Запит відправлено',
		sent_description: 'Ваш запит на відсутність замовлень був надісланий. Після реєстраці він буде відображений у розкладі.',
		too_short_headline: 'Помилка',
		too_short_description: 'Виключення в розкладі повинно тривати принаймні 2 години.',
		error_headline: 'Щось пішло не так',
		error_description: 'Будь ласка, зв`яжіться з нами, щоб ми могли якнайшвидше виправити помилку.',
		choose_datetime: 'Вкажіть дату та час, коли ви не бажаєте приймати замовлення.',
		date: 'Дата',
		send_button: 'Надіслати',
		choose_date_range: 'Виберіть дати, з яких і по які ви не бажаєте приймати замовлення.',
		first_day: 'Початок відсутності',
		last_day: 'Закінчення відсутності',
	},
	individual_instructions: {
		title: 'Ваші iнструкції',
		no_instructions: 'У вас немає спеціальних інструкцій.',
	},
	payouts: {
		all_finished: 'Все закрито',
		invoiced: 'Сплачено за рахунком',
		hours_worked: 'Відпрацьовано годин',
		bonuses: 'Бонуси',
		total: 'Загальний дохід (з бонусом)',
		yes: 'Так',
		not_yet: 'Ще ні',
		not_possible: 'Неможливо',
		week_invoicing: 'Цього тижня видача рахунків вже відбувається. Гроші зазвичай приходять протягом 7 днів.',
		week_unfinished: 'Цього тижня ви зможете виставити рахунок лише після того, як всі завдання будуть закриті. Це зазвичай стається в понеділок після 20:00.',
		payouts: 'Виплати',
		in_period_from: 'В період від',
		to: 'до',
		unpaid: 'ви маєте непогашених',
		hour_short: 'г',
		kc_short: 'Кч',
		check: 'Перевірити',
		error: 'При запиті на виплату сталася помилка. Будь ласка, зв’яжіться з нами.',
	},
	invoice_check: {
		documents: 'Документи',
		instructions: 'Перш ніж затвердити все, перевірте рахунок та звіт. Після підтвердження не можна вносити зміни.',
		your_invoice: 'Ваш рахунок',
		your_report: 'Ваш звіт',
		check_invoice: 'Перевірте рахунок. Ви можете зробити це, відкривши його.',
		check_report: 'Перевірте звіт. Ви можете зробити це, відкривши його.',
		confirm: 'Підтвердити',
		confirm_successful: 'Затвердження успішно виконано. Ви можете повернутися назад.',
		confirm_error: 'Під час підтвердження сталася помилка. Будь ласка, зв’яжіться з нами.',
		alert: 'Ви не зобов’язані виставляти рахунок цим способом. Якщо у вас є власна програма для виставлення рахунків, зв’яжіться з нами.',
	},
}

export default lang;