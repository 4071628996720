import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

// Dynamic imports for code splitting
const Login = React.lazy(() => import('../pages/Login'));
const Logout = React.lazy(() => import("../pages/Logout"));
const ForgottenPassword = React.lazy(() => import('../pages/ForgottenPassword'));
const OrderDetail = React.lazy(() => import('../pages/OrderDetail'));
const OrderReport = React.lazy(() => import('../pages/OrderReport'));
const Home = React.lazy(() => import('../pages/Home'));
const Settings = React.lazy(() => import('../pages/Settings'));
const Salaries = React.lazy(() => import('../pages/Salaries'));
const ShiftWizard = React.lazy(() => import('../pages/ShiftWizard'));
const Shifts = React.lazy(() => import('../pages/Shifts'));
const OrderFinish = React.lazy(() => import('../pages/OrderFinish'));
const VacationForm = React.lazy(() => import('../pages/VacationForm'));
const IndividualInstructions = React.lazy(() => import('../pages/IndividualInstructions'));
const Payouts = React.lazy(() => import('../pages/Payouts'));
const InvoiceCheck = React.lazy(() => import('../pages/InvoiceCheck'));
const ForceLogin = React.lazy(() => import('../pages/ForceLogin'));
const RegionPricing = React.lazy(() => import('../pages/RegionPricing'));
const Legal = React.lazy(() => import('../pages/Legal'));
const LinkPage = React.lazy(() => import('../pages/LinkPage'));
const Version = React.lazy(() => import('./Version'));

const Router = () => {
	return (
		<BrowserRouter>
			<React.Suspense fallback={<div>Loading...</div>}>
				<Routes>
					{/* Account */}
					<Route path="/" exact element={<Home />} />
					<Route path="/objednavky" exact element={<Home />} />
					<Route path="/prihlaseni" exact element={<Login />} />
					<Route path="/zapomenute-heslo" exact element={<ForgottenPassword />} />
					<Route path="/nove-heslo" exact element={<ForgottenPassword />} />
					<Route path="/nastaveni" exact element={<Settings />} />
					<Route path="/vyplaty" exact element={<Salaries />} />
					<Route path="/platby" exact element={<Payouts />} />
					<Route path="/vydelky" exact element={<Payouts />} />
					<Route path="/kontrola" exact element={<InvoiceCheck />} />
					<Route path="/smeny" exact element={<Shifts />} />
					<Route path="/rozvrh" exact element={<Shifts />} />
					<Route path="/smeny-nastaveni" exact element={<ShiftWizard />} />
					<Route path="/rozvrh-nastaveni" exact element={<ShiftWizard />} />
					<Route path="/dovolene" exact element={<VacationForm />} />
					<Route path="/instrukce" exact element={<IndividualInstructions />} />
					<Route path="/dulezite-odkazy" exact element={<LinkPage />} />
					{/* Orders */}
					<Route path="/objednavka/:order_id" exact element={<OrderDetail />} />
					<Route path="/hlaseni/:order_id" exact element={<OrderReport />} />
					<Route path="/ukonceni/:order_id" exact element={<OrderFinish />} />
					{/* Legal */}
					<Route path="/cenik-lokalit" exact element={<RegionPricing />} />
					<Route path="/vseobecne-obchodni-podminky" exact element={<Legal />} />
					{/* Dev */}
					<Route path="/force-login" exact element={<ForceLogin />} />
					<Route path="/odhlaseni" exact element={<Logout />} />
					<Route path="/verze" exact element={<Version />} />
					<Route path="/error" exact element={<div><button onClick={() => { throw new Error(process.env.REACT_APP_ENVIRONMENT) }}>Error {process.env.REACT_APP_ENVIRONMENT}</button></div>} />
				</Routes>
			</React.Suspense>
		</BrowserRouter>
	);
};

export default Router;