import React from 'react';

const lang = {
	login: {
		welcome: 'Vítejte!',
		email: 'Email',
		password: 'Heslo',
		submit: 'Přihlásit se',
		forgotten_password: 'Zapomenuté heslo',
		error_title: 'Přihlášení se nezdařilo',
		error_content: 'Zkuste zadat údaje znovu. Pokud ani to nepůjde, klikněte na "Zapomenuté heslo".',
	},
	logout: 'Odhlásit se',
	forgotten_password: {
		back_to_login: 'Zpět na přihlášení',
		email: 'Email',
		code: 'Kód z emailu',
		password: 'Nové heslo',
		password_confirmation: 'Heslo znovu',
		submit_email: 'Odeslat email',
		submit_code: 'Ověřit kód',
		submit_password: 'Změnit heslo',
		success_title: 'Email odeslán',
		success_content: 'Email pro obnovení hesla byl odeslán na adresu ',
		error_title: 'Nastala chyba',
		error_content: 'Při obnovení hesla nastala chyba, zkuste to prosím ještě jednou.',
	},
	navigation: {
		back: 'Zpět',
	},
	modals: {
		ok: 'OK',
		cancel: 'Zrušit',
	},
	menu: {
		home: 'Domů',
		orders: 'Úklidy',
		shifts: 'Rozvrh',
		settings: 'Nastavení',
		salary: 'Výdělek',
		training: 'Školení',
		standards: 'Standardy úklidu',
		legal: 'Bezpečností listy a návody',
		contact: 'Kontakty',
		warehouse: 'Sklad Brno',
		phone: 'Telefon',
		links: 'Důležité odkazy',
		prices: 'Ceník lokalit',
		toc: 'Všeobecné obchodní podmínky',
	},
	settings: {
		name: 'Jméno',
		email: 'Email',
		phone: 'Telefon',
		address: 'Adresa',
		bank_account: 'Účet',
		birthday: 'Narození',
		drivers_licence: 'Řidičák',
		zones: 'Zóny',
		password: 'Heslo',
		change_password: 'Změnit',
		personal_info: 'Osobní údaje',
		alert_change_details: 'Pokud potřebujete změnit vaše údaje, protože již nejsou aktuální, napište nám na info@youklid.cz',
	},
	days: [
		{
			short: 'Po',
			long: 'Pondělí',
		},
		{
			short: 'Út',
			long: 'Úterý',
		},
		{
			short: 'St',
			long: 'Středa',
		},
		{
			short: 'Čt',
			long: 'Čtvrtek',
		},
		{
			short: 'Pá',
			long: 'Pátek',
		},
		{
			short: 'So',
			long: 'Sobota',
		},
		{
			short: 'Ne',
			long: 'Neděle',
		},
	],
	months: [
		'Leden',
		'Únor',
		'Březen',
		'Duben',
		'Květen',
		'Červen',
		'Červenec',
		'Srpen',
		'Září',
		'Říjen',
		'Listopad',
		'Prosinec',
	],
	orders: {
		confirm_headline: 'Úklidy k potrvzení',
		detail: 'Detail',
		confirm: 'Přijmout',
		fill_report: 'Vyplnit hlášení',
		begin_timer: 'Zahájit úklid',
		end_timer: 'Ukončit úklid',
		active: 'Aktivní úklid',
		next: 'Další úklid',
		map: 'Mapa',
		no_orders: 'V tento moment nemáte přiřazené žádné úklidy',
		orders: 'Úklidy',
		hide: 'Skrýt',
		more: 'Více úklidů...',
		method: 'Method',
		bonus: 'Bonus',

		important: 'Důležité',
		details: 'Detaily',
		actions: 'Akce',
		first_cleaning_headline: 'Toto je klientův první úklid s vámi',
		first_cleaning_checklist:
			<>
				<li><strong>Představte se</strong> a řekněte, že jste přišli vykonat <strong>Youklid</strong></li>
				<li>Poproste ho, aby Vám ukázal byt a <strong>sdělil</strong> Vám <strong>své požadavky na úklid</strong></li>
				<li>Poproste ho, aby Vám ukázal, <strong>kde jsou prostředky, mop, vysavač a odpadkové pytle 🧹</strong></li>
				<li>Zjistěte, jestli má klient květiny a jak je zalévat (raději méně než více!).</li>
				<li>Pokud budete potřebovat používat <strong>žehličku, pračku nebo jiné spotřebiče - neboj se zeptat, jak se používají</strong>
				</li>
				<li>Řekněte klientovi stručně, <strong>co budete dělat a čím začnete</strong></li>
				<li>Zeptejte se zda má ještě nějaké <strong>přání k úklidu</strong></li>
				<li><strong>Poděkujte</strong> a dejte se do práce 😊</li>
			</>,
		cards_cards: 'Zanechte u klienta kartičku s číslem',
		cards_description: 'Pokud kartičku nemáte, netřeba nic dělat. Kartičky vám průběžně rozdáváme a slouží našim klientům jako poděkování.',
		date: 'Datum',
		duration: 'Trvání',
		note: 'Poznámka',
		note_1: <>Poznámka<br />(od klienta)</>,
		note_2: <>Poznámka<br />(od youklidu)</>,
		note_3: <>Poznámka<br />(domácnost)</>,
		note_4: <>Poznámka<br />(o klientovi)</>,
		address: 'Adresa',
		route: 'Cesta',
		travel_time: 'Doba kterou trvá cesta na úklid z vašeho domova.',
		minutes: 'min',
		phone: 'Telefon',
		cleaning_order: 'Pořadí',
		cleaning: 'úklid',
		id: 'ID',
		frequency: 'Frekvence',
		work_time: 'Odpracováno',
		unfinished: 'Neukončeno',
		client: 'Klient',
		services: 'Služby',
		cleaning_uppercase: 'Úklid',
		no_services: 'Klient nezadal žádné služby',
		guides: 'Návody',
		cleaning_standards: 'Co uklízíme',
		have_cleaning: 'Máte úklid',
		started: 'Úklid zahájen',
		start: 'Zahájit úklid',
		finished: 'Úklid ukončen',
		finish: 'Ukončit úklid',
		reported: 'Hlášení vyplněno',
		report: 'Vyplnit hlášení',

		error_finishing_headline: 'Úklid se nepodařilo ukončit',
		error_finishing_description: 'Prosím kontaktujte nás, abychom mohli chybu co nejdříve odstranit.',
		shorter_cleaning: 'Kratší úklid',
		longer_cleaning: 'Přetažený úklid',
		should_be_from: 'Úklid měl být od',
		by_timer_its_from: 'Podle vašeho měření jste uklízela od',
		till: 'do',
		thats: 'To je o',
		less_minutes: 'minut méně než si klient objednal.',
		more_minutes: 'minut více než si klient objednal.',
		finish_short: 'Ukončit',
		return: 'Vrátit se k úklidu',
		client_approval: 'Protažení úklidu musí odsouhlasit klient. Souhlasil klient s doplacením času navíc?',
		set_time: 'Chci nastavit čas',
		time_now: 'Aktuální čas',
		ordered_time: 'Objednaný čas',
		notes_to_report: 'Všechny další poznámky piště prosím do hlášení. My si je pak přečteme a zařídíme to.',
		order_not_loaded: 'Tato stránka se špatně načetla. Prosím obnovte tuto stránku. Pokud to nepomůže, kontaktujte nás.',

		report_sent: 'Hlášení uloženo',
		report_error_headline: 'Chyba s odesláním',
		report_error_description: 'Nastala chyba při odesílání hlášení.',
		enough_time: 'Bylo na úklid dost času?',
		ok_time: 'Ano, délka byla akorát',
		not_enough: 'Ne, čas nestačil',
		better_time: 'Jaký čas doporučujete?',
		more_information: 'Ještě něco co bychom měli vědět?',
		write_here: 'Napište zde...',
		send_report: 'Odeslat hlášení',
	},
	salaries: {
		salaries_headline: 'Výplaty',
		worked_hours: 'Odpracováno hodin',
		bonuses: 'Bonusy k výplatě',
		total: 'Hrubá mzda (s bonusem)',
		hour_short: 'h',
		kc_short: 'Kč',
		disclaimer: <>
			Čistou mzdu, která vám přijde na účet po odpočtu zákonného sociálního a zdravotního pojištění lze vypočítat na stránce <a href="https://www.vypocet.cz/cista-mzda">kalkulačky hrubé mzdy</a> (u DPP nad 10000 Kč měsíčně). Podrobnější informace jsme vám sepsali do <a href="https://skoleni.youklid.cz/cista-mzda">článku o výpočtu mzdy</a> ve školení.
		</>,
		alert_mistake_contact: <>
			Nesedí vám výplata? Rádi se vám na to podíváme, stačí napsat na info@youklid.cz a vysvětlit, co vám na výplatě nesedí. Nejprve si však prosíme důkladně přečtěte <a href="https://skoleni.youklid.cz/cista-mzda">informace o zákonných odvodech</a> ve školení.
		</>,
	},
	shifts: {
		change_headline: 'Změna rozvrhu',
		change_button: 'Nastavení',
		vacation_headline: 'Vyjímka rozvrhu',
		vacation_button: 'Odhlásit',
		no_shift: 'Zrušený den',
	},
	shift_wizzard: {
		sent_headline: 'Nastavení uloženo',
		sent_description: 'Vaše nastavení rozvrhu bylo uloženo. Nyní musíme vaší změnu schválit a poté se vám zobrazí nový rozvrh dní.',
		error_headline: 'Něco se pokazilo',
		error_description: 'Prosím kontaktujte nás, abychom mohli chybu co nejdříve odstranit.',
		previous: 'Předchozí',
		next: 'Další',
		send_button: 'Odeslat ke zpracování', 		// změna
		page_1_1: 'Vítejte v nastavení rozvrhu. Během chvíle si zde nastavíte, které dny chcete uklízet. Pamatujte že máte v nastavení velkou volnost, ovšem čím méně času zvolíte, tím méně úklidů se k vám dostane.',
		page_1_2: 'Po odeslání vašeho požadovaného nastavení váš rozvrh musíme zpracovat. Dáváme při tom pozor, abyste si mohli nechat co nejvíce opakujících se úklidů. Většinou projde zpracování rychle, v případě nesrovnalosti vás budeme kontaktovat.',
		page_2_1: 'Náš systém vámi nastavený rozvrh automaticky předvyplní pro každý budoucí týden. Je tomu tak především kvůli opakujícícm se úklidům a vaší pohodlnější práci při zadávání. V případě změn máte možnost váš rozvrh pomocí tlačítka pro vyjímku rozvrhu upravit.',			// změna
		page_2_2: 'Chcete nastavit rozvrh stejně každý týden nebo odlišně pro sudé a liché týdny?',
		weekly: 'Ano',
		fortnightly: 'Sudé/liché',
		page_3: 'Nyní zvolte, které dny chcete uklízet.',
		even_week: 'Tento týden je sudý',
		odd_week: 'Tento týden je lichý',
		page_4: 'Nastavte časy, ve kterých chcete dostávat zakázky. Čím více času, tím více možných úklidů',
		days: [
			[
				'Pondělí',
				'Pondělí (liché)',
			],
			[
				'Úterý',
				'Úterý (liché)',
			],
			[
				'Středa',
				'Středa (lichá)',
			],
			[
				'Čtvrtek',
				'Čtvrtek (lichý)',
			],
			[
				'Pátek',
				'Pátek (lichý)',
			],
			[
				'Sobota',
				'Sobota (lichá)',
			],
			[
				'Neděle',
				'Neděle (lichá)',
			],
			[
				'Pondělí',
				'Pondělí (sudé)',
			],
			[
				'Úterý',
				'Úterý (sudé)',
			],
			[
				'Středa',
				'Středa (sudá)',
			],
			[
				'Čtvrtek',
				'Čtvrtek (sudý)',
			],
			[
				'Pátek',
				'Pátek (sudý)',
			],
			[
				'Sobota',
				'Sobota (sudá)',
			],
			[
				'Neděle',
				'Neděle (sudá)',
			],
		],
	},
	vacations: {
		page_1: 'Kolik dní?',
		one_day: 'Jeden den',
		multiple_days: 'Více dní',
		sent_headline: 'Požadavek odeslán',
		sent_description: 'Váš požadavek o dny bez zakázek byl odeslán. Po zpracování se zobrazí v rozvrhu.',
		too_short_headline: 'Chyba',
		too_short_description: 'Vyjímka v rozvrhu musí trvat alespoň 2 hodiny.',
		error_headline: 'Něco se pokazilo',
		error_description: 'Prosím kontaktujte nás, abychom mohli chybu co nejdříve odstranit.',
		choose_datetime: 'Zadejte datum a čas kdy nechcete přijímat zakázky.',
		date: 'Datum',
		send_button: 'Odeslat',
		choose_date_range: 'Zvolte data od kdy do kdy nechcete uklízet.',
		first_day: 'Začátek vyjímky',
		last_day: 'Konec vyjímky',
	},
	individual_instructions: {
		title: 'Vaše instrukce',
		no_instructions: 'Nemáte žádné speciální instrukce.',
	},
	payouts: {
		all_finished: 'Vše uzavřeno',
		invoiced: 'Fakturováno',
		hours_worked: 'Odpracováno hodin',
		bonuses: 'Bonusy',
		total: 'Výdělky (s bonusem)',
		yes: 'Ano',
		not_yet: 'Ještě ne',
		not_possible: 'Nelze',
		week_invoicing: 'Tento týden je právě fakturován. Peníze většinou přijdou do 7 dnů.',
		week_unfinished: 'Tento týden můžete fakturovat až budou všechny úklidy uzavřené. To bývá až v pondělí po 20:00.',
		payouts: 'Výdělky',
		in_period_from: 'V období od',
		to: 'do',
		unpaid: 'máte nevyplacených',
		hour_short: 'h',
		kc_short: 'Kč',
		check: 'Zkontrolovat',
		error: 'Při žádosti o výplatu nastala chyba. Prosím kontaktujte nás.',
	},
	invoice_check: {
		documents: 'Dokumenty',
		instructions: 'Před tím než vše odsouhlasíte, zkontrolujte prosím fakturu i výkaz. Po potvrzení nepůjdou už provádět žádné změny.',
		your_invoice: 'Vaše faktura',
		your_report: 'Váš výkaz',
		check_invoice: 'Zkontrolujte fakturu. To uděláte tím že jí otevřete.',
		check_report: 'Zkontrolujte výkaz. To uděláte tím že ho otevřete.',
		confirm: 'Odsouhlasit',
		confirm_successful: 'Výdělek úspěšně odsouhlasen. Můžete se vrátit zpět.',
		confirm_error: 'Při konfirmaci nastala chyba. Prosím kontaktujte nás.',
		alert: 'Fakturu nemusíte vystavovat tímto způsobem. Pokud máte vlastní fakturační program, kontaktujte nás.',
	},
}

export default lang;