import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import * as Sentry from "@sentry/react";

Sentry.init({
	environment: process.env.REACT_APP_ENVIRONMENT,
	dsn: "https://d762600956a3a5b92346f1285c8bc81c@o1400792.ingest.us.sentry.io/4507413752381440",
	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration(),
	],
	tracesSampleRate: 1.0,
	tracePropagationTargets: [/*"localhost",*/ /^https:\/\/youklid\.cz\/api/],
	replaysSessionSampleRate: 0,
	replaysOnErrorSampleRate: 1.0,
	autoSessionTracking: true,		// Release Health
	release: process.env.REACT_APP_RELEASE_VERSION
});

console.log(Array.prototype.findLast);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	// <React.StrictMode>
		<App />
	// </React.StrictMode>
);