import { useGlobalState } from './GlobalStateContext';
import cs from '../langs/cs.js';
import ua from '../langs/ua.js';
import en from '../langs/en.js';

const useLang = () => {
	const { globalState } = useGlobalState();

	const lang = globalState?.lang?.locale ?? globalState.defaultLang;

	switch (lang) {
		case 'cs': return cs;
		case 'ua': return ua;
		case 'en': return en;
		default: return cs;
	}
};

export default useLang;