// src/GlobalStateContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import defaultState from './state';
import LoadingPage from '../components/LoadingPage';
import PropTypes from 'prop-types';

const GlobalStateContext = createContext();

export const GlobalStateProvider = ({ children }) => {
	const [globalState, setGlobalState] = useState(defaultState);
	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		if (loaded) {
			localStorage.setItem('globalState', JSON.stringify(globalState));
		}
	}, [globalState]);

	useEffect(() => {
		if (localStorage.getItem('globalState') === null) {
			setGlobalState(defaultState);
			setLoaded(true);
			return;
		}

		const stateString = localStorage.getItem('globalState');
		const parsed = JSON.parse(stateString);

		setGlobalState(prevState => ({ ...prevState, ...parsed }));
		setLoaded(true);
	}, []);

	const updateState = (newState) => {
		if (newState == null) {
			setGlobalState({});
			return;
		}
		setGlobalState(prevState => ({ ...prevState, ...newState }));
	};

	return (
		<>
			{loaded && <GlobalStateContext.Provider value={{ globalState, setGlobalState: updateState }}>
				{children}
			</GlobalStateContext.Provider>}
			{!loaded && <LoadingPage />}
		</>
	);
};

GlobalStateProvider.propTypes = {
	children: PropTypes.node,
};

export const useGlobalState = () => {
	const context = useContext(GlobalStateContext);
	if (!context) {
		throw new Error('useGlobalState must be used within a GlobalStateProvider');
	}
	return context;
};