import { Button, ConfigProvider, Modal } from 'antd';
import React from 'react';
import { useGlobalState } from './GlobalStateContext';
import useLang from './I18n';
import { CheckCircleFilled, CloseCircleFilled, ExclamationCircleFilled, InfoCircleFilled } from '@ant-design/icons';
import theme from '../styles/theme.js';
import PropTypes from 'prop-types';

const ModalLog = ({ children }) => {
	const { globalState, setGlobalState } = useGlobalState();
	const t = useLang();

	const clearAlert = () => {
		setGlobalState({ alert: null });
	};

	const config = {
		primary: {
			severity: 'primary',
			icon: <InfoCircleFilled />,
			color: theme.palette.primary.main,
			background: theme.palette.white,
		},
		secondary: {
			severity: 'default',
			icon: null,
			color: theme.palette.secondary.main,
			background: theme.palette.white,
		},
		success: {
			severity: 'primary',
			icon: <CheckCircleFilled />,
			color: theme.palette.success.main,
			background: theme.palette.success.light,
		},
		warning: {
			severity: 'text',
			icon: <ExclamationCircleFilled />,
			color: theme.palette.warning.main,
			background: theme.palette.warning.light,
		},
		danger: {
			severity: 'primary',
			icon: <CloseCircleFilled />,
			color: theme.palette.danger.main,
			background: theme.palette.danger.light,
		},
		info: {
			severity: 'dashed',
			icon: <InfoCircleFilled />,
			color: theme.palette.info.main,
			background: theme.palette.info.light,
		},
		light: {
			severity: 'text',
			icon: null,
			color: theme.palette.primary.main,
			background: theme.palette.white,
		},
	}

	const severity = globalState?.alert?.severity;
	const values = config[severity] ?? config.light;

	return (
		<>
			<ConfigProvider theme={{ components: { Modal: { headerBg: values.background, contentBg: values.background, }, }, }}>
				<Modal
					open={globalState.alert != null}
					onOk={clearAlert}
					onCancel={clearAlert}
					okType={values.severity}
					title={<>
						{values.icon != null &&
							<span style={{ marginRight: 10, color: values.color, fontSize: 22 }}>
								{values.icon}
							</span>
						}
						{globalState?.alert?.title}
					</>}
					footer={[
						<Button key='modal' type={values.severity} onClick={clearAlert}>
							{t.modals.ok}
						</Button>,
					]}
				>
					{globalState?.alert?.content}
				</Modal>
			</ConfigProvider>
			{children}
		</>
	);
};

ModalLog.propTypes = {
	children: PropTypes.node,
};

export default ModalLog;