import React from 'react';
import Router from './utils/Router';
import { ConfigProvider } from 'antd';
import theme from './styles/theme';
import { GlobalStateProvider } from './utils/GlobalStateContext';
import ModalLog from './utils/ModalLog';
import './styles/global.css';

const App = () => {
	return (
		<GlobalStateProvider>
			<ConfigProvider theme={theme.antd}>
				<ModalLog>
					<Router />
				</ModalLog>
			</ConfigProvider>
		</GlobalStateProvider>
	);
};

export default App;

// navigator.serviceWorker.register(
// 	new URL('./utils/service-worker.js', import.meta.url),
// 	{ type: 'module' }
// );