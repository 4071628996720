import React from 'react';

const lang = {
	login: {
		welcome: 'Welcome!',
		email: 'Email',
		password: 'Password',
		submit: 'Log In',
		forgotten_password: 'Forgot Password',
		error_title: 'Login Failed',
		error_content: 'Please try entering your credentials again. If that doesn\'t work, click "Forgot Password".',
	},
	logout: 'Logout',
	forgotten_password: {
		back_to_login: 'Back to Login',
		email: 'Email',
		code: 'Code from Email',
		password: 'New password',
		password_confirmation: 'Confirm Password',
		submit_email: 'Send Email',
		submit_code: 'Verify Code',
		submit_password: 'Change Password',
		success_title: 'Email Sent',
		success_content: 'An email to reset your password has been sent to ',
		error_title: 'Error Occurred',
		error_content: 'There was an error while resetting the password, please try again.',
	},
	navigation: {
		back: 'Back',
	},
	modals: {
		ok: 'OK',
		cancel: 'Cancel',
	},
	menu: {
		home: 'Home',
		orders: 'Cleanings',
		shifts: 'Schedule',
		settings: 'Settings',
		salary: 'Earnings',
		training: 'Training',
		standards: 'Cleaning Standards',
		legal: 'Safety Sheets and Guides',
		contact: 'Contacts',
		warehouse: 'Brno Warehouse',
		phone: 'Call',
		links: 'Important links',
		prices: 'Price list of locations',
		toc: 'Terms and Conditions',
	},
	settings: {
		name: 'Name',
		email: 'Email',
		phone: 'Phone',
		address: 'Address',
		bank_account: 'Bank Account',
		birthday: 'Birthday',
		drivers_licence: 'Driver\'s License',
		zones: 'Zones',
		password: 'Password',
		change_password: 'Change',
		personal_info: 'Personal Information',
		alert_change_details: 'If you need to update your details because they are no longer current, please email us at info@youklid.cz',
	},
	days: [
		{
			short: 'Mon',
			long: 'Monday',
		},
		{
			short: 'Tue',
			long: 'Tuesday',
		},
		{
			short: 'Wed',
			long: 'Wednesday',
		},
		{
			short: 'Thu',
			long: 'Thursday',
		},
		{
			short: 'Fri',
			long: 'Friday',
		},
		{
			short: 'Sat',
			long: 'Saturday',
		},
		{
			short: 'Sun',
			long: 'Sunday',
		},
	],
	months: [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	],
	orders: {
		confirm_headline: 'Cleanings to confirm',
		detail: 'Detail',
		confirm: 'Accept',
		fill_report: 'Fill report',
		begin_timer: 'Start cleaning',
		end_timer: 'End cleaning',
		active: 'Active cleaning',
		next: 'Next cleaning',
		map: 'Map',
		no_orders: 'You have no assigned cleanings at the moment',
		orders: 'Cleanings',
		hide: 'Hide',
		more: 'More cleanings...',
		method: 'Method',
		bonus: 'Bonus',

		important: 'Important',
		details: 'Details',
		actions: 'Actions',
		first_cleaning_headline: 'This is your first cleaning with the client',
		first_cleaning_checklist:
			<>
				<li><strong>Introduce yourself</strong> and say that you&apos; ve come to perform <strong>Youklid</strong></li>
				<li>Ask them to show you around the apartment and <strong>share</strong> their <strong>cleaning requirements</strong> with you</li>
				<li>Ask them to show you where the supplies, mop, vacuum cleaner, and garbage bags 🧹 are</li>
				<li>Find out if the client has flowers and how to water them (preferably less rather than more!)</li>
				<li>If you need to use <strong>an iron, washing machine, or other appliances - don&apos; t hesitate to ask how they&apos; re used</strong></li>
				<li>Briefly tell the client <strong>what you&apos; ll be doing and where you&apos; ll start</strong></li>
				<li>Ask if they have any <strong>additional cleaning requests</strong></li>
				<li><strong>Thank them</strong> and get to work 😊</li>
			</>,
		cards_cards: 'Leave a card with a number for the client',
		cards_description: 'If you don`t have a card, there`s no need to do anything. We provide cards regularly and they serve as a token of appreciation to our clients.',
		date: 'Date',
		duration: 'Duration',
		note: 'Note',
		note_1: <>Note<br />(from client)</>,
		note_2: <>Note<br />(from youklid)</>,
		note_3: <>Note<br />(household)</>,
		note_4: <>Note<br />(about client)</>,
		address: 'Address',
		route: 'Route',
		travel_time: 'Time it takes to travel to the cleaning from your home.',
		minutes: 'min',
		phone: 'Phone',
		cleaning_order: 'Cleaning order',
		cleaning: 'Cleaning',
		id: 'ID',
		frequency: 'Frequency',
		work_time: 'Worked',
		unfinished: 'Unfinished',
		client: 'Client',
		services: 'Services',
		cleaning_uppercase: 'Cleaning',
		no_services: 'Client has not entered any services',
		guides: 'Guides',
		cleaning_standards: 'What we clean',
		have_cleaning: 'Do you have a cleaning',
		started: 'Cleaning started',
		start: 'Start Cleaning',
		finished: 'Cleaning finished',
		finish: 'Finish Cleaning',
		reported: 'Report filled',
		report: 'Fill Report',

		error_finishing_headline: 'Failed to finish cleaning',
		error_finishing_description: 'Please contact us so we can resolve the issue as soon as possible.',
		shorter_cleaning: 'Shorter cleaning',
		longer_cleaning: 'Extended cleaning',
		should_be_from: 'Cleaning should have started at',
		by_timer_its_from: 'According to your measurement, you cleaned from',
		till: 'to',
		thats: 'That\'s',
		less_minutes: 'minutes less than the client ordered.',
		more_minutes: 'minutes more than the client ordered.',
		finish_short: 'Finish',
		return: 'Return to cleaning',
		client_approval: 'Extension of cleaning must be approved by the client. Did the client agree to pay extra time?',
		set_time: 'I want to set the time',
		time_now: 'Current time',
		ordered_time: 'Ordered time',
		notes_to_report: 'Please write any additional notes in the report. We will read them and take care of it.',
		order_not_loaded: 'This page did not load properly. Please refresh this page. If that doesn`t help, contact us.',


		report_sent: 'Report saved',
		report_error_headline: 'Sending error',
		report_error_description: 'There was an error sending the report.',
		enough_time: 'Was there enough time for cleaning?',
		ok_time: 'Yes, the duration was just right',
		not_enough: 'No, the time wasn\'t enough',
		better_time: 'What time do you recommend?',
		more_information: 'Is there anything else we should know?',
		write_here: 'Write here...',
		send_report: 'Send Report',
	},
	salaries: {
		salaries_headline: 'Salaries',
		worked_hours: 'Worked Hours',
		bonuses: 'Bonuses to Salary',
		total: 'Gross Salary (with Bonus)',
		hour_short: 'h',
		kc_short: 'CZK',
		disclaimer: <>
			The net salary, which comes to your account after deduction of mandatory social and health insurance, can be calculated on the page of the <a href="https://www.vypocet.cz/cista-mzda">gross salary calculator</a> (for employment agreements above 10000 CZK per month). More detailed information is provided in our <a href="https://skoleni.youklid.cz/cista-mzda">article on salary calculation</a> in training.
		</>,
		alert_mistake_contact: <>
			Does your payment not match?We&apos; re happy to look into it, just write to info @youklid.cz and explain what doesn&apos; t match in your payment. But first, please thoroughly read the <a href="https://skoleni.youklid.cz/cista-mzda">information on statutory deductions</a> in training.
		</>,
	},
	shifts: {
		change_headline: 'Schedule Change',
		change_button: 'Settings',
		vacation_headline: 'Schedule Exception',
		vacation_button: 'Sign Off',
		no_shift: 'Canceled Day',
	},
	shift_wizzard: {
		sent_headline: 'Settings Saved',
		sent_description: 'Your schedule settings have been saved. We now need to approve your change, and then your new schedule will be displayed.',
		error_headline: 'Something Went Wrong',
		error_description: 'Please contact us so we can fix the error as soon as possible.',
		previous: 'Previous',
		next: 'Next',
		send_button: 'Submit for Processing',
		page_1_1: 'Welcome to schedule settings. In a moment, you will set which days you want to clean. Remember, you have great flexibility in the settings, but the less time you choose, the fewer cleanings you will receive.',
		page_1_2: 'After submitting your desired settings, we need to process your schedule. We make sure you can keep as many recurring cleanings as possible. Processing usually goes quickly, and in case of discrepancies, we will contact you.',
		page_2_1: 'Our system automatically pre-fills your set schedule for each future week. This is mainly due to recurring cleanings and your convenience in entering data. In case of changes, you can adjust your schedule using the schedule exception button.',
		page_2_2: 'Do you want to set the schedule the same every week or differently for even and odd weeks?',
		weekly: 'Yes',
		fortnightly: 'Even/Odd',
		page_3: 'Now choose which days you want to clean.',
		even_week: 'This week is even',
		odd_week: 'This week is odd',
		page_4: 'Set the times you want to receive orders. The more time, the more possible cleanings.',
		days: [
			[
				'Monday',
				'Monday (odd)',
			],
			[
				'Tuesday',
				'Tuesday (odd)',
			],
			[
				'Wednesday',
				'Wednesday (odd)',
			],
			[
				'Thursday',
				'Thursday (odd)',
			],
			[
				'Friday',
				'Friday (odd)',
			],
			[
				'Saturday',
				'Saturday (odd)',
			],
			[
				'Sunday',
				'Sunday (odd)',
			],
			[
				'Monday',
				'Monday (even)',
			],
			[
				'Tuesday',
				'Tuesday (even)',
			],
			[
				'Wednesday',
				'Wednesday (even)',
			],
			[
				'Thursday',
				'Thursday (even)',
			],
			[
				'Friday',
				'Friday (even)',
			],
			[
				'Saturday',
				'Saturday (even)',
			],
			[
				'Sunday',
				'Sunday (even)',
			],
		],
	},
	vacations: {
		page_1: 'How many days?',
		one_day: 'One day',
		multiple_days: 'Multiple days',
		sent_headline: 'Request Sent',
		sent_description: 'Your request for days without orders has been sent. Once processed, it will appear in the schedule.',
		too_short_headline: 'Error',
		too_short_description: 'Schedule exemption must last at least 2 hours.',
		error_headline: 'Something Went Wrong',
		error_description: 'Please contact us so we can fix the error as soon as possible.',
		choose_datetime: 'Enter the date and time when you do not want to receive orders.',
		date: 'Date',
		send_button: 'Send',
		choose_date_range: 'Choose the dates from when to when you do not want to clean.',
		first_day: 'Start of exemption',
		last_day: 'End of exemption',
	},
	individual_instructions: {
		title: 'Your instructions',
		no_instructions: 'You have no special instructions.',
	},
	payouts: {
		all_finished: 'All finished',
		invoiced: 'Invoiced',
		hours_worked: 'Hours worked',
		bonuses: 'Bonuses',
		total: 'Total earnings (with bonus)',
		yes: 'Yes',
		not_yet: 'Not yet',
		not_possible: 'Not possible',
		week_invoicing: 'Invoicing for this week is ongoing. Money usually arrives within 7 days.',
		week_unfinished: 'You can invoice for this week after all tasks are closed. This typically happens on Monday after 20:00.',
		payouts: 'Payouts',
		in_period_from: 'In period from',
		to: 'to',
		unpaid: 'you have unpaid',
		hour_short: 'h',
		kc_short: 'CZK',
		check: 'Check',
		error: 'An error occurred while requesting payout. Please contact us.',
	},
	invoice_check: {
		documents: 'Documents',
		instructions: 'Before you approve everything, please check the invoice and report. No changes can be made after confirmation.',
		your_invoice: 'Your invoice',
		your_report: 'Your report',
		check_invoice: 'Check the invoice. You can do this by opening it.',
		check_report: 'Check the report. You can do this by opening it.',
		confirm: 'Confirm',
		confirm_successful: 'Approval successful. You can return now.',
		confirm_error: 'Error occurred during confirmation. Please contact us.',
		alert: 'You do not have to issue the invoice this way. If you have your own invoicing software, please contact us.',
	},
};

export default lang;
